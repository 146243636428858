import React, { ReactChild } from "react";

import "./styles/_Button.scss";
import { Link } from "gatsby";

export interface ButtonProps extends React.HTMLAttributes<any> {
  children?: ReactChild;
  buttonType?: "button" | "submit" | "reset";
  buttonText?: string;
  type?: string;
  to?: string;
  href?: string;
}

function Button({
  children,
  type = "link",
  buttonType = "button",
  buttonText = "Hire us",
  to = "/",
  href = "#",
  ...options
}: ButtonProps) {
  if (type === "link") {
    return (
      <a className="animated-btn" href={href} {...options}>
        {children}
      </a>
    );
  }
  if (type === "gatsbyLink") {
    return (
      <Link className="animated-btn" to={to}>
        {buttonText}
      </Link>
    );
  }
  return (
    <button className="animated-btn" type={buttonType} {...options}>
      {children}
    </button>
  );
}

export default Button;
