import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import PageHead from "@Components/PageHead";
import CallToAction from "@Components/CallToAction";

function Backend() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.serverHero.childImageSharp.fluid}>
        <h2 className="head-title">Server Development</h2>
      </PageHead>
      <CallToAction />
    </>
  );
}

export default Backend;

export const PageQuery = graphql`
  query {
    serverHero: file(relativePath: { eq: "serverHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
