import React from "react";
import Button from "./Button";

function CallToAction() {
  return (
    <div
      style={{
        display: "flex",
        padding: "5rem 0",
        justifyContent: "center",
      }}
    >
      <p
        style={{
          fontSize: "2.4rem",
          fontWeight: "bold",
          alignSelf: "center",
          margin: 0,
          fontFamily: "poppins",
        }}
      >
        Got project to work on?
      </p>
      <Button buttonText="Contact us" type="gatsbyLink" to="/contact" />
    </div>
  );
}

export default CallToAction;
